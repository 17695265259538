import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionService } from '../../../../../../../service/session/session.service';
import { CaixaPostalService } from '../../../../../../../service/caixa-postal/caixa-postal.service';

import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../../../utils/swalObject';

@Component({
  selector: 'app-respostas',
  templateUrl: 'respostas.component.html',
  styleUrls: ['./respostas.component.scss']
})
export class RespostasComponent implements OnInit {
  _mensagem: any;
  @Input('mensagem')
  set mensagem(value: any) {
    value.dataEnvioOriginal = value.dataEnvio;
    value.dataEnvio = moment(value.dataEnvio).format('DD/MM/YYYY HH:mm:ss');
    this._mensagem = value;
  }
  get mensagem(): any {
    return this._mensagem;
  }
  @Output() onReplyMessage: EventEmitter<any> = new EventEmitter<any>();

  reply: boolean = false;
  loading: boolean = false;
  bloquearResposta: boolean = false;

  constructor(
    private sessionService: SessionService,
    private caixaPostalService: CaixaPostalService
  ) { }

  ngOnInit() {
    this.verificarLeituraMensagem(this.mensagem);
    if (this.mensagem.tipoComunicado.respostaBloqueada) {
      this.verificarRespostaBloqueada(this.mensagem.tipoComunicado.prazoCiencia, this.mensagem.dataEnvioOriginal);
    }
  }

  obterAnexo(protocolo, anexo) {
    this.caixaPostalService
      .baixarAnexo(protocolo, anexo.codigo)
      .subscribe(
        res => this.baixarAnexo(res, anexo),
        error => console.log(error)
      );
  }

  obterTodosAnexos(protocolo) {
    this.caixaPostalService
      .baixarTodosAnexos(protocolo)
      .subscribe(res => this.baixarTodos(res), error => console.log(error));
  }

  gerarProtocolo(mensagem) {
    this.loading = true;
    this.caixaPostalService.gerarProtocolo(mensagem.protocolo).subscribe(
      response => {
        this.loading = false;
        var blob = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `dossie`);
      },
      error => {
        this.loading = false;
        Swal.fire(
          getSwal(
            'success',
            '',
            'Comunicado ' +
            mensagem.assunto +
            ' recebido em ' +
            // moment(mensagem.dataEnvio).format('DD/MM/YYYY HH:mm:ss') +
            mensagem.dataEnvio +
            ' de número ' +
            mensagem.protocolo,
            'ok',
            null
          )
        );
      }
    );
  }

  baixarAnexo(data, anexo) {
    var blob = new Blob([data], { type: 'application/pdf' });
    FileSaver.saveAs(blob, `${anexo.nomeAnexo}`);
  }

  baixarTodos(data) {
    var blob = new Blob([data], { type: 'application/zip' });
    FileSaver.saveAs(blob, `arquivos.zip`);
  }

  validaUsuarioResponde(mensagem) {
    //funcao que verifica se o remetente é diferente do usuario a responder ou se o remetente é a aplicacao
    //caso algumas das condições seja verdadeira, o usuario poderá responder, caso contrário, não
    let session: Object = this.sessionService.getSession();
    let remetente = mensagem.remetente;
    if (mensagem.situacaoMensagem.situacao === 2) return false;
    else if (
      remetente.usuario &&
      remetente.usuario.cpfCnpj !== session['usuario'].cpfCnpj
    )
      return true;
    else if (remetente.aplicacao !== null) return true;
    else return false;
  }

  replyMessage() {
    this.reply = true;
    this.onReplyMessage.emit(this.reply);
  }
  verificarLeituraMensagem(mensagem) {
    let usuario = JSON.parse(localStorage.getItem('session'));
    let cpfCnpjUsuario = usuario.usuario.cpfCnpj;
    let destinatario;

    if (mensagem.destinatario.usuario !== null) {
      destinatario = mensagem.destinatario.usuario.cpfCnpj;
    }

    if ((destinatario == cpfCnpjUsuario && mensagem.leitura.length == 0)) {
      if (mensagem.situacaoMensagem.situacao != 2) {
        this.marcarComoLida(mensagem);
        console.log('aaaa', mensagem.situacaoMensagem.situacao)
      }
    }
  }

  marcarComoLida(mensagem) {
    this.caixaPostalService.mensagemLida(mensagem.protocolo).subscribe(
      res => {
      },
      error => {
        console.log(error);
      }
    );
  }

  verificarRespostaBloqueada(prazoCiencia, dataEnvio) {
    if (prazoCiencia > 0) {
      let dataLimite = moment(dataEnvio).add(prazoCiencia, 'days');
      let dataAtual = moment();
      let verificaBloqueio = moment(dataLimite).isAfter(dataAtual);

      if (verificaBloqueio == true) {
        this.bloquearResposta = false;
      } else {
        this.bloquearResposta = true;
      }
    }
  }
}
